import React from 'react'
import Icon from '../utils/Icon'

import styled from 'styled-components'

const LogoIcon = styled(Icon)`
  height: 48px;
  width: 120px;
  fill: white;
`

const Logo = () => <LogoIcon name="logo" />

export default Logo
