import React from 'react'
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'

const BackgroundSection = ({ className, image, children }) => (
  <BackgroundImage
    Tag="section"
    className={className}
    fluid={image}
    backgroundColor={`#2e2529`}
  >
    {children}
  </BackgroundImage>
)

const StyledBackgroundSection = styled(BackgroundSection)`
  height: 100vh;
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 40.74%,
    #000000 100%
  );
  &:before,
  &:after {
    background-position: center 70%;
    background-repeat: inherit;
    background-size: cover;
  }
  .hero-body {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`

const SlideHero = ({ slide, image }) => {
  return (
    <StyledBackgroundSection image={image}>
      <div className="container hero-body">
        <h1 className="title has-text-white has-text-centered">
          {slide.title}
        </h1>
        <h2 className="subtitle has-text-white has-text-centered">
          {slide.subtitle}
        </h2>
      </div>
    </StyledBackgroundSection>
  )
}

export default SlideHero
