import React from 'react'
import { Link } from 'gatsby'

import '../style/style.scss'

import Hero from './Hero'
import Container from './Container'
import Footer from './Footer'

import Icon from '../utils/Icon'
import styled from 'styled-components'
import Telephone from './Telephone'
import NavbarItems from './HeadNavbar/NavbarItems'
const LogoIcon = styled(Icon)`
  height: 2rem;
  width: 60px;
  fill: #2e2529;
`

class Layout extends React.Component {
  render() {
    const { slides, images } = this.props
    const { location, title, children } = this.props

    const rootPath = `${__PATH_PREFIX__}/`
    let header

    if (location.pathname === rootPath) {
      header = <Hero slides={slides} images={images} />
    } else {
      header = (
        <nav className="navbar is-primary">
          <Container>
            <div className="navbar-brand">
              <div className="navbar-item">
                <Link to="/">
                  <LogoIcon name="logo" />
                </Link>
              </div>
              <div className="navbar-item">
                <NavbarItems />
              </div>
            </div>
            <div className="navbar-menu">
              <div className="navbar-end">
                <div className="navbar-item has-text-primary">
                  <Telephone color="has-text-dark" />
                </div>
              </div>
            </div>
          </Container>
        </nav>
      )
    }
    return (
      <>
        {header}
        {children}
        <Footer />
      </>
    )
  }
}

export default Layout
