import React from 'react'
import { Link } from 'gatsby'

import styled from 'styled-components'

const NavLink = styled.div`
  display: inline-block;
  margin: 0 0.75rem;
`

const HeadNavbarItem = ({ children, to, color }) => {
  return (
    <NavLink>
      <Link to={to} className={color} activeClassName="is-active">
        {children}
      </Link>
    </NavLink>
  )
}

export default HeadNavbarItem
