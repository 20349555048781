import React from 'react'

import SliderWraper from 'react-slick'
import '../../node_modules/slick-carousel/slick/slick.scss'
import '../../node_modules/slick-carousel/slick/slick-theme.scss'

const Slider = ({ children, options }) => {
  return <SliderWraper {...options}>{children}</SliderWraper>
}

export default Slider
