import React from 'react'
import * as contact from './Contact/index'

const Telephone = ({ color }) => {
  return (
    <span className={`${color} is-hidden-mobile`}>
      tel. {contact.mobilePhoneNumber}
    </span>
  )
}

export default Telephone
