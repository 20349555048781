import React from 'react'
import HeadNavbarItem from './HeadNavbarItem'
const NavbarItems = ({ color }) => {
  return (
    <>
      <HeadNavbarItem color={color} to="/o-firmie">
        o firmie
      </HeadNavbarItem>
      <HeadNavbarItem color={color} to="/oferta">
        oferta
      </HeadNavbarItem>
      <HeadNavbarItem color={color} to="/kontakt">
        kontakt
      </HeadNavbarItem>
    </>
  )
}

export default NavbarItems
