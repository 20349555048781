export const mobilePhoneNumber = '692 376 678'
export const mobilePhoneAlarmNumber = '604 155 996'
export const staticPhoneNumber = '12 431 10 38'
export const email = 'lancet.key@gazeta.pl'
export const isOpen = {
  workingDay: [
    'Karmelicka: pon. - pt. 9:00 - 17:00',
    'Kalwaryjska: pon. - pt. 11:00 - 16:00',
  ],
}
export const currentAdress = {
  street: 'ul. Karmelicka 17',
  code: '31-133 Kraków',
}

export const secondAdress = {
  street: 'ul. Kalwaryjska 84',
  code: '30-504 Kraków',
}
export const lastAdress = `ul. Kalwaryjska 82 i Karmelicka${String.fromCharCode(
  160
)}12`
