import React from 'react'
import styled from 'styled-components'
import Logo from './Logo'
import Container from './Container'
import HeadNavbar from './HeadNavbar/HeadNavbar'
import HeroSlider from '../components/HeroSlider'

const HeroIsFullHeight = ({ className, children }) => (
  <section className={`hero is-fullheight ${className}`}>{children}</section>
)
const StyledHeroIsFullHeight = styled(HeroIsFullHeight)`
  position: relative;
`
const StyledHeroSliderPosition = styled.div`
  background-color: #2e2529;
  height: 100vh;
`
const ApsolutePositionWraper = styled.div`
  position: absolute;
  width: 100%;
  bottom: ${props => (props.isBottom ? 0 : 'auto')};
`
const Hero = ({ slides, images }) => {
  return (
    <>
      <StyledHeroIsFullHeight>
        <ApsolutePositionWraper>
          <div className="hero-head">
            <nav className="navbar">
              <Container>
                <div className="navbar-brand">
                  <a className="navbar-item">
                    <Logo />
                  </a>
                </div>
              </Container>
            </nav>
          </div>
        </ApsolutePositionWraper>
        <StyledHeroSliderPosition>
          <HeroSlider slides={slides} images={images} />
        </StyledHeroSliderPosition>
        <ApsolutePositionWraper isBottom>
          <Container>
            <div className="hero-foot">
              <HeadNavbar />
            </div>
          </Container>
        </ApsolutePositionWraper>
      </StyledHeroIsFullHeight>
    </>
  )
}

export default Hero
