import React from 'react'
import Container from './Container'
import * as contact from './Contact/index'
import { Link } from 'gatsby'
import styled from 'styled-components'

const StyledFoterLink = styled.span`
  a {
    color: #f2bd2a;
    &:hover {
      color: white;
    }
  }
`

const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <div className="columns is-multiline">
          <div className="column ">
            <div className="level is-mobile">
              <div className="level-left">
                <div className="content is-small hes-has-text-white">
                  <h1 className="heading is-size-7 has-text-white">Linki</h1>
                  <StyledFoterLink>
                    <Link to="/o-firmie">o firmie</Link>
                  </StyledFoterLink>
                  <br />
                  <StyledFoterLink>
                    <Link to="/oferta">oferta</Link>
                  </StyledFoterLink>
                  <br />
                  <StyledFoterLink>
                    <Link to="/kontakt">kontakt</Link>
                  </StyledFoterLink>
                </div>
              </div>
              <div className="level-right">
                <div className="content is-small has-text-white">
                  <h1 className="heading is-size-7 has-text-white">
                    Lokalizacje:
                  </h1>
                  <p>
                    {contact.currentAdress.street}
                    <br />
                    {contact.currentAdress.code}
                  </p>
                  <p>
                    {contact.secondAdress.street}
                    <br />
                    {contact.currentAdress.code}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="column is-12 content is-small has-text-white has-text-centered">
            © {new Date().getFullYear()} F.H.U. Lancet
          </div>
        </div>
      </Container>
    </footer>
  )
}

export default Footer
