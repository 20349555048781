import React from 'react'

import Slider from './Slider'
import SlideHero from './SlideHero'

const PostSliderOptions = {
  dotsClass: 'slick-dots hero-slick-dots__color-inwert',
  lazyLoad: true,
  autoplay: true,
  autoplaySpeed: 3500,
  fade: true,
  pauseOnHover: true,
  // pauseOnFocus: true,
  adaptiveHeight: true,
  dots: true,
  arrows: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  initialSlide: 0,
}

const HeroSlider = ({ slides, images }) => {
  const AllImages = images.edges

  const AllSlides = slides.edges.map(frame => {
    const slide = frame.node.frontmatter
    const slideRelativeDirectory = frame.node.fields.slug.slice(1, -1)
    const imageFromeSlideFolder = AllImages.filter(
      image => image.node.relativeDirectory === slideRelativeDirectory
    )
    const [imageDataFluid] = imageFromeSlideFolder
    const imageSlide = imageDataFluid.node.childImageSharp.fluid

    return (
      <SlideHero
        key={frame.node.fields.slug}
        slide={slide}
        image={imageSlide}
      />
    )
  })

  return <Slider options={PostSliderOptions}>{AllSlides}</Slider>
}

export default HeroSlider
