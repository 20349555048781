import React from 'react'

import styled from 'styled-components'
import Icon from './../../utils/Icon'
import { Link } from 'gatsby'
import Telephone from './../Telephone'
import NavbarItems from './NavbarItems'

const IconWraper = styled(Icon)`
  width: 1rem;
  height: 1rem;
  fill: white;
  line-height: 1.5;
`

const Media = ({ className, children }) => (
  <div className={`${className} media`}>{children}</div>
)

const MediaStyled = styled(Media)`
  padding: 2rem 0.75rem;
  justify-content: center;
  .media-content {
    flex-grow: 0;
  }
`

const HeadNavbar = () => {
  return (
    <MediaStyled>
      <div className="media-content">
        <NavbarItems color="has-text-white" />
      </div>
      <div className="media-right">
        <Telephone color="has-text-white" />
      </div>
    </MediaStyled>
  )
}

export default HeadNavbar
